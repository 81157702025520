/* App.css */

:root {
  --rect-width: 70vw;
  --rect-height: 50vh;
  --logo-size: 10vmin;
  --logo-gap: 2vmin;
  --background-color: #1F1D47;
  --button-background: linear-gradient(232deg, #074DE3 0%, #11BBF3 100%);
  --button-hover-background: linear-gradient(135deg, #6a11cb 0%, #34c6f1 100%);
  --button-text-color: #ffffff;
  --modal-background: rgba(50, 8, 117, 0.95);
  --text-color: #ffffff;
  --description-color: #ffffff;
  --accent-color: #ffffff;
  --category-title-color: #074DE3; /* Category title color */
}

html, body {
  margin: 0;
  font-family: 'Dinosaur', sans-serif;
  height: 100%;
  overflow-x: hidden;
  background-color: var(--background-color); /* Add background color to body */
}

.App {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--text-color);
}

.ecosystem-bar {
  width: 100%;
  background-color: #1F1D47;
  z-index: 1000;
  display: flex;
  justify-content: center;
  padding: 10px 20px; /* Adjusted padding to include left and right */
  box-sizing: border-box; /* Ensure padding is included in total width */
}

.ecosystem-image {
  max-width: 100%;
  height: auto;
}

.categories {
  display: grid;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px 20px; /* Removed top padding */
  box-sizing: border-box;
  margin: 0 auto; /* Center the categories */
}

.search-container {
  width: 100%;
  margin-bottom: 10px; /* Reduced space between search box and categories */
  grid-column: 1 / -1; /* Make search box span full width of grid */
}

.search-box {
  font-size: 16px; /* Prevent zoom on mobile */
  padding: 8px 12px;
  border: 1px solid var(--category-title-color); /* Border color matches category titles */
  border-radius: 6px; /* Same radius as categories */
  width: 100%;
  max-width: 150px; /* Set max-width to 150px */
  background-color: transparent;
  color: var(--text-color);
  -webkit-appearance: none;
  appearance: none;
}

.search-box:focus {
  outline: none;
  border-color: var(--category-title-color); /* Maintain border color on focus */
  box-shadow: none; /* Remove any default focus styles */
}

.category {
  position: relative;
  transition: transform 0.3s;
  border-radius: 6px;
  background-color: #26264d;
}

.category-button {
  width: 100%;
  background: var(--button-background);
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-family: 'Dinosaur', sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: var(--button-text-color);
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 5px;
}

.project-item {
  display: flex;
  width: 100%;
  transition: background 0.3s;
}

.project-item span {
  margin-top: 17px;
  font-size: 14px;
}

.project-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.project-item img {
  width: 50px;
  height: 50px;
  margin-right: 0px;
}

@media (min-width: 768px) {
  .categories {
    grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
  }
}

@media (max-width: 767px) {
  .categories {
    grid-template-columns: repeat(2, 1fr); /* Ensure two categories per row on mobile */
  }
}

/* Keyframes and other CSS animations */

@keyframes flyInFromLeft {
  from {
    transform: translateX(-100vw);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes flyInFromRight {
  from {
    transform: translateX(100vw);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes flyInFromTop {
  from {
    transform: translateY(-100vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes flyInFromBottom {
  from {
    transform: translateY(100vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.category.flyInFromLeft {
  animation: flyInFromLeft 1s ease-out forwards;
}

.category.flyInFromRight {
  animation: flyInFromRight 1s ease-out forwards;
}

.category.flyInFromTop {
  animation: flyInFromTop 1s ease-out forwards;
}

.category.flyInFromBottom {
  animation: flyInFromBottom 1s ease-out forwards;
}
