/* Main container for the news list */
.news-list {
  padding: 0;
  margin: 0;
}

/* Each news item */
.news-item {
  margin-bottom: 10px; /* Adjusted margin for better spacing between news items */
  list-style-type: none;
  padding: 10px;
  border-bottom: 1px solid #ccc; /* Subtle separator between news items */
  font-family: 'Dinosaur', sans-serif; /* Apply Dinosaur font here */
}

html, body {
  font-family: 'Dinosaur', sans-serif;
}

/* News item title */
.news-item h2 {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
  font-family: 'Dinosaur', sans-serif; /* Ensure Dinosaur font is applied here */
}

/* Ensure all markdown content uses Dinosaur font */
.markdown-content {
  font-size: 1rem;
  color: #fff; /* Text color */
  line-height: 1.6; /* Readable line height */
  word-wrap: break-word; /* Break long words or URLs */
  overflow-wrap: break-word; /* Prevent overflow with long URLs */
  white-space: normal; /* Avoid horizontal scrolling */
  margin-bottom: 20px; /* Space before the "Updated" and "Author" section */
  font-family: 'Dinosaur', sans-serif; /* Ensure Dinosaur font is applied here */
}

/* Apply Dinosaur font to all possible markdown elements */
.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6,
.markdown-content p,
.markdown-content ul,
.markdown-content ol,
.markdown-content blockquote,
.markdown-content pre,
.markdown-content code {
  font-family: 'Dinosaur', sans-serif; /* Apply Dinosaur font to all markdown elements */
}

/* Author and date container */
.news-item p {
  font-size: 1rem;
  color: #ddd; /* Light color for author and date */
  margin: 5px 0 0px; /* Top margin for spacing between markdown content and author/date section */
  font-family: 'Dinosaur', sans-serif; /* Apply Dinosaur font here */
}

/* Styling for hyperlinks */
.markdown-content a {
  color: white; /* White text for hyperlinks */
  text-decoration: underline; /* Underlined links */
}

/* Styling for "View on Cyrator" link */
.view-on-cyrator {
  color: #00c8b7; /* Example: green color for the link */
  text-decoration: none;
  font-weight: bold;
  margin-top: 10px;
  display: inline-block;
}

.view-on-cyrator:hover {
  text-decoration: underline;
  color: #00c8b7; /* Change color on hover */
}