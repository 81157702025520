:root {
  --modal-background: rgba(50, 8, 117, 0.95);
  --text-color: #ffffff;
  --description-color: #ffffff;
  --accent-color: #ffffff;
  --button-background: linear-gradient(232deg, #074DE3 0%, #11BBF3 100%);
  --button-hover-background: linear-gradient(135deg, #6a11cb 0%, #34c6f1 100%);
  --button-text-color: #ffffff;
  --border-color: #ddd;
  --scrollbar-bg: #ffffff00;
  --scrollbar-thumb: #6b11cb65;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
}

.modal-content {
  background: var(--modal-background);
  padding: 20px;
  border-radius: 15px;
  position: relative;
  width: 90%;
  max-width: 600px;
  height: 85vh;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  color: var(--text-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--accent-color);
  z-index: 1001;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-logo {
  display: block;
  margin-bottom: -30px;
  width: 120px;
  height: 120px;
}

.project-title-with-bg {
  margin-top: 0px;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.modal-content h3 {
  color: var(--accent-color);
  font-size: 1.5rem;
  margin-bottom: 0px;
}

.content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}

.tab-buttons {
  display: flex;
  justify-content: space-between; /* Spreads the tab buttons and the share button */
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0;
  margin-top: 10px;
  border-radius: 10px 10px 0 0;
}

.tab-button {
  background: none;
  border: none;
  padding: 10px 20px;
  color: var(--button-text-color);
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  font-size: 1rem;
  text-align: center;
  width: auto;
  border-bottom: 3px solid transparent;
}

.tab-button.active {
  color: var(--button-hover-background);
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: 3px solid var(--button-hover-background);
}

.tab-button.active:first-child {
  border-top-left-radius: 10px;
}

.tab-button.active:last-child {
  border-top-right-radius: 10px;
}

.tab-button:hover {
  color: var(--button-hover-background);
}

.content-box {
  flex-grow: 1;
  overflow-y: auto;
  margin: 0;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.6) !important;
  border-radius: 0 0 10px 10px;
  color: var(--description-color);
  text-align: left;
  position: relative;
}

.content-box::-webkit-scrollbar {
  width: 12px;
}

.content-box::-webkit-scrollbar-track {
  background: var(--scrollbar-bg);
  border-radius: 10px;
}

.content-box::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 10px;
  border: 3px solid var(--scrollbar-bg);
}

.social-icons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  gap: 30px;
}

.social-icon {
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: transform 0.3s;
}

.social-icon:hover {
  transform: scale(1.2);
}

/* Share Button Styling within Tabs Row */
.share-button {
  background-color: var(--button-background);
  border: none;
  padding: 8px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-left: auto; /* Align it to the right */
}

.share-button:hover {
  background-color: var(--button-hover-background);
}

.share-button svg {
  width: 20px;
  height: 20px;
  fill: var(--button-text-color);
}

/* Share Success Notification */
.share-success {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 0.9rem;
  text-align: center;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}

/* News Article Styles */
.news-article h3 {
  color: #fff;
  text-align: left;
}

.news-article p {
  color: #ddd;
  text-align: left;
}

.news-article hr {
  border-color: #fff;
  border-width: 2px;
}

/* Footer for buttons and social icons */
.modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}
